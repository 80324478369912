<template>
    <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
        <!-- <ul class="navbar-nav">
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Dashboard' }">
                    <template #icon>
                        <shop />
                    </template>
                </profilenav-collapse>
            </li>
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Tables' }">
                    <template #icon>
                        <office />
                    </template>
                </profilenav-collapse>
            </li>
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Billing' }">
                    <template #icon>
                        <credit-card />
                    </template>
                </profilenav-collapse>
            </li>
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Profile' }">
                    <template #icon>
                        <customer-support />
                    </template>
                </profilenav-collapse>
            </li>
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Sign In' }">
                    <template #icon>
                        <document />
                    </template>
                </profilenav-collapse>
            </li>
            <li class="nav-item">
                <profilenav-collapse navText="" :to="{ name: 'Sign Up' }">
                    <template #icon>
                        <spaceship />
                    </template>
                </profilenav-collapse>
            </li>
        </ul> -->
    </div>
    <div style="padding-top: 30px">

    </div>
    <div>
        <div style="justify-content: space-between; padding-right: 20px; padding-left: 20px;" class="col-auto d-flex">
            <div>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            Hello,
                        </h6>
                        <span style="font-size: 28px !important; font-weight:700" class="text-xs">John Doe</span>
                        <star-rating :inline="true" :star-size="14" :read-only="true" :show-rating="false"
                            :rating="4"></star-rating>
                        <div class="d-flex" style="justify-content: space-between;">
                            <span style="font-size: 14px; color: black">Overall Rating</span>
                            <i style="padding-top: 5px" type="button"
                                class="fa fa-info-circle text-dark cursor-pointer btn-tooltip" aria-hidden="true"
                                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"
                                data-container="body" data-animation="true">
                            </i>
                        </div>
                    </div>
                </li>
            </div>
            <div class="avatar avatar-xl position-relative">
                <img src="@/assets/img/bruce-mars.jpg" style="border-radius: 9rem; box-shadow: none !important;"
                    alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
            </div>
        </div>
        <div style="padding-left: 20px; padding-right: 20px">
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                <div class="d-flex flex-column">
                    <h6 style="font-size: 12px !important; color: grey !important"
                        class="mb-1 text-dark font-weight-bold text-sm">
                        Copy your referral link and share with your friends on your social channel
                    </h6>
                </div>
            </li>
        </div>
        <div style="padding-left: 20px; padding-right: 10px; display: flex">
            <input class="form-control form-control-sm" style="padding: 0.5rem !important; border-radius: 12px"
                v-on:focus="$event.target.select()" ref="referralLink" readonly :value="text" id="referral"
                placeholder="sortikadl.page.link/Zwert" name="referralLink" />
            <div style="margin-left: 10px"></div>
            <soft-button style="padding-left: 20px; padding-right: 20px; box-shadow:none" class="mb-2"
                variant="gradient" color="success" @click="copy">Copy
                Link
            </soft-button>
        </div>
        <div>
            <router-link :to="{ name: 'Profile' }">
                <a style="padding-left: 20px !important; padding-bottom: 20px !important; text-decoration: underline;"
                    class="mb-0 btn btn-link pe-3 ps-0 ms-auto">View
                    Profile <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i></a>
            </router-link>
        </div>
    </div>

    <div class="mb-xl-0 mb-4">
        <packages-card
            style="margin-left: 20px; margin-right: 20px; box-shadow: none !important; margin-bottom: 20px;" />
    </div>
    <div class="mb-xl-0 mb-4">
        <add-card style="margin-left: 20px; margin-right: 20px; box-shadow: none !important; margin-bottom: 20px;" />
    </div>
    <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card :class="cardBg" textPrimary="Need Help?" textSecondary="Please check our docs"
      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/" label="Documentation"
      icon="ni ni-diamond" />
  </div> -->
</template>
<script>
// import ProfilenavCollapse from "./ProfilenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import Settings from "../../components/Icon/Settings.vue";
import PackagesCard from "@/examples/Cards/PackagesCard.vue";
import AddCard from "@/examples/Cards/AddCard.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
// @ts-ignore
import StarRating from 'vue-star-rating'

export default {
    name: "ProfilenavList",
    props: {
        cardBg: String,
    },
    data() {
        return {
            title: "Sortika UI Dashboard",
            controls: "dashboardsExamples",
            isActive: "active",
            showMenu: false,
            disabled: true,
            text: 'Copied to clipboard!'
        };
    },
    components: {
        PackagesCard,
        AddCard,
        // SoftInput,
        SoftButton,
        StarRating,
        // ProfilenavCollapse,
        // Shop,
        // Office,
        // CreditCard,
        // CustomerSupport,
        // Document,
        // Spaceship,
    },
    methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        },
        copy() {
            this.$refs.clone.focus();
            document.execCommand('copy');
        },
    },
    mounted() {
        setTooltip();
    },
};
</script>
