<template>
    <div class="card bg-transparent shadow-xl">
        <div class="overflow-hidden position-relative border-radius-xl" :style="{
            backgroundImage:
                'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        }">
            <span class="mask" :class="`bg-gradient-${card.background}`"></span>
            <div class="card-body position-relative z-index-1 p-3">
                <h5 class="text-white pb-2">
                    {{ card.number }}
                </h5>
                <div class="d-flex">
                    <div class="d-flex">

                    </div>
                    <div class="w-20 d-flex align-items-end justify-content-end"
                        :class="$store.state.isRTL ? 'me-auto' : 'ms-auto'">
                    </div>
                </div>
                <div style="margin-top: 10px">
                    <router-link :to="{ name: 'Sortika Packages' }">
                        <soft-button class="mb-2" variant="gradient" color="success" full-width>See the benefits
                        </soft-button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import img from "@/assets/img/curved-images/curved14.jpg";
import SoftButton from "@/components/SoftButton.vue";
// import img1 from "@/assets/img/logos/mastercard.png";

export default {
    name: "PackagesCard",
    components: {
        // SoftAvatar,
        SoftButton,
    },
    props: {
        card: {
            type: Object,
            number: String,
            holderName: String,
            expiryDate: String,
            holderText: String,
            expiryText: String,
            background: String,
            default: () => ({
                number: "Sortika Packages",
                holderName: "Jack Peterson",
                expiryDate: "11/22",
                holderText: "Card Holder",
                expiryText: "Expires",
                background: "dark",
            }),
        },
    },
    data() {
        return {
            img,
            // img1,
        };
    },
};
</script>
