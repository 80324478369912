<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <!-- <div class="mx-auto mb-4 text-center col-lg-8">
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            About Us
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            FAQs
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Packages
          </a>
          <a href="javascript:;" target="_blank" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Terms and Conditions
          </a>
        </div> -->
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <img class="clickable-image" src="@/assets/img/Play-Store.png" alt="Clickable Image"
            style="width: 150px; margin-right: 5px;" @click="redirectToPlayStore" />

          <img class="clickable-image" src="@/assets/img/App-Store.png" alt="Clickable Image"
            style="width: 150px; margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#modal-coming-soon" />
        </div>
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a href="https://facebook.com/sortikaapp" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a href="https://twitter.com/SortikaA" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a href="https://www.youtube.com/@sortikaapp" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-youtube"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Phone: <strong>+254798087598</strong>
          </p>
          <p class="mb-0 text-secondary">
            Email: <strong>support@sortika.co.ke</strong>
          </p>
          <p class="mb-0 text-secondary">
            Office: The Mirage Towers T2, Mezannine 2, <br />Suite 7, Westlands, Nairobi, Kenya
          </p>
          <div style="margin-bottom: 20px"></div>
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} Powered by Sortika
          </p>
        </div>
      </div>
    </div>
  </footer>

  <div class="col-md-4">
    <div class="modal fade" id="modal-coming-soon" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">

            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex" style="
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
              text-align: center;
            ">
              <h3>Coming Soon!</h3>
            </div>
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
              ">

              <client-only>
                <Vue3Lottie animationLink="https://lottie.host/1a96cca9-af18-4c9b-a41a-249c61838f73/v4SKduQg6c.json"
                  :height="300" :width="300" />
              </client-only>

            </div>
            <div class="d-flex" style="
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 20px;
              text-align: center;
            ">
              <!-- <h5>Your contribution of KES 600.00 has been received</h5> -->
              <h4>On Apple iOS</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
export default {
  name: "app-footer",
  components: {
    Vue3Lottie,
  },

  methods: {
    redirectToPlayStore() {
      // Replace 'com.example.yourapp' with your app's package name
      const packageName = 'com.sortika.egroup';

      // Create the Play Store URL for your app
      const playStoreUrl = `https://play.google.com/store/apps/details?id=${packageName}`;

      // Redirect the user to the Play Store
      window.location.href = playStoreUrl;
    },
  }
};
</script>
