<template>
  <!-- <navbar btn-background="bg-gradient-primary" /> -->
  <div class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{
    backgroundImage:
      'url(' + require('@/assets/img/curved-images/curved2.jpg') + ')',
  }">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to eGroups!</h1>
          <p class="text-white text-lead">Let's make it count</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <div class="d-flex" style="justify-content: center">
              <h4 style="margin-bottom: 4rem">{{ groupData?.name }}</h4>
              <img v-if="groupData?.is_verified == 'true'" style="
                  border-radius: 9rem !important;
                  box-shadow: none !important;
                  height: 3% !important;
                  width: 3% !important;
                  margin-top: 0.2rem;
                  margin-left: 0.5rem;
                " src="@/assets/img/verified.svg" alt="Verified" class="shadow-sm w-100 h-100 border-radius-lg"
                data-bs-toggle="tooltip" data-bs-placement="top" aria-hidden="true"
                data-bs-original-title="Verified eGroup" aria-label="Verified eGroup" />
            </div>
            <div class="avatar avatar-xl position-relative">
              <img v-if="groupData.image" style="
                  border-radius: 9rem !important;
                  box-shadow: none !important;
                  height: 300% !important;
                  width: 300% !important;
                " :src="groupData?.image" alt="Group Image" class="shadow-sm w-100 h-100 border-radius-lg" />
              <img v-else style="
                  border-radius: 9rem !important;
                  box-shadow: none !important;
                  height: 200% !important;
                  width: 200% !important;
                " src="@/assets/img/logo-ct.png" alt="Group Image" class="shadow-sm w-100 h-100 border-radius-lg" />
            </div>
            <div style="margin-bottom: 10px"></div>
            <div class="d-flex" style="justify-content: center">
              <div>
                <soft-badge class="text-xs" style="
                    background-color: #a1fcde;
                    color: #000;
                    font-weight: 600;
                    font-size: 1rem !important;
                    padding: 0.3rem;
                    border-radius: 0.5rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    text-transform: capitalize;
                  ">
                  {{
                    groupData?.institute ? groupData?.institute : groupData?.name === "Help Break The Cycle Of Crime" ?
                    "PFKENYA" : groupData?.name === "Youths For Sanctuary Doors" ? "PCEA" : "Sortika"
                  }}
                </soft-badge>
              </div>
              <div style="margin-left: 10px"></div>
              <div>
                <soft-badge class="text-xs" style="
                    background-color: #a1fcde;
                    color: #000;
                    font-weight: 600;
                    font-size: 1rem !important;
                    padding: 0.3rem;
                    border-radius: 0.5rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    text-transform: capitalize;
                  ">
                  <!-- {{ groupData?.locality }} -->
                  {{ groupData?.locality ? groupData?.locality : "Nairobi" }}
                </soft-badge>
              </div>
            </div>
            <div class="d-flex" style="justify-content: center">
              <soft-button color="success" variant="gradient" class="my-4 mb-2" style="height: 45px"
                data-bs-toggle="modal" data-bs-target="#modal-contribute">Contribute</soft-button>
              <button href="javascript:;" class="btn btn-outline-secondary ms-2" style="
                  border: 2px solid;
                  padding-left: 30px;
                  padding-right: 30px;
                  margin-top: 1.5rem;
                  height: 8%;
                " data-bs-toggle="modal" data-bs-target="#modal-share">
                Share
              </button>
            </div>
            <div style="margin-bottom: 30px"></div>

            <div class="flex-container">
              <span>
                <h6 class="mb-0 font-weight-bolder">KES</h6>
              </span>
              <span class="spacer"></span>
              <span>
                <h6 class="mb-0 font-weight-bolder">
                  <vue3-autocounter ref="counter" :startAmount="0" :endAmount="parseInt(
                    Number(
                      groupData.saved_amount ? groupData.saved_amount : 0.0
                    )
                  )
                    " :duration="3" prefix="" separator="," decimalSeparator="." :decimals="2" :autoinit="true" />
                </h6>
              </span>
              <span class="spacer"></span>
              <span>
                <h6 class="mb-0">out of</h6>
              </span>
              <!-- <span class="spacer"></span>
              <span>
                <h6 class="mb-0">of</h6>
              </span> -->
              <span class="spacer"></span>
              <span>
                <h6 class="mb-0">
                  <vue3-autocounter ref="counter" :startAmount="0" :endAmount="parseInt(
                    Number(
                      groupData.target_amount
                        ? groupData.target_amount
                        : 0.0
                    )
                  )
                    " :duration="3" prefix="" separator="," decimalSeparator="." :decimals="2" :autoinit="true" />
                </h6>
              </span>
              <!-- <div style="margin-left: 5px"></div>
              <h6 style="font-weight: 800; color: #000;">KES 1,512,600.00</h6>
              <div style="margin-left: 5px"></div>
              <h6>of KES 3,900,000.00</h6> -->
            </div>
            <div style="padding-left: 20px; padding-right: 20px">
              <soft-progress color="success" variant="gradient" :percentage="percentage(groupData?.saved_amount, groupData?.target_amount)
                " />
            </div>
            <div style="margin-bottom: 10px"></div>
            <div class="container" style="box-shadow: none !important">
              <div class="row justify-content-center">
                <div>
                  <div class="card z-index-0" style="box-shadow: none !important">
                    <marquee behavior="scroll" direction="left" class="text-dark py-2 font-weight-bold"
                      style="border-radius: 5px; box-shadow: none !important">
                      <span v-for="(
                          contributor, i
                        ) in groupData.external_contributors" :key="i">
                        {{ contributor.name?.toUpperCase().split(" ")[0] }} -
                        <span class="text-success">KES {{ formatAmount(contributor.amount) }}</span>
                        | &nbsp;
                      </span>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 30px"></div>
            <div class="d-flex" style="justify-content: space-evenly">
              <div>
                <h5 class="mb-0 font-weight-bolder" style="font-size: 28px">
                  <vue3-autocounter ref="counter" :startAmount="0" :endAmount="groupData?.saved_amount ? groupData?.saved_amount : 0
                    " :duration="3" prefix="" separator="," decimalSeparator="." :decimals="2" :autoinit="true" />
                </h5>
                <p class="mb-0 text-l text-capitalize font-weight-bold">
                  Contributions
                </p>
              </div>
              <div>
                <h5 class="mb-0 font-weight-bolder" style="font-size: 28px">
                  <vue3-autocounter ref="counter" :startAmount="0" :endAmount="groupData?.days_remaining ? groupData?.days_remaining : 0
                    " :duration="3" prefix="" separator="," decimalSeparator="." :decimals="0" :autoinit="true" />
                </h5>
                <!-- <h5 class="mb-0 font-weight-bolder" style="font-size: 28px">
                  {{ groupData?.days_remaining ? groupData?.days_remaining : 0 }}
                </h5> -->
                <p class="mb-0 text-l text-capitalize font-weight-bold">
                  Days Left
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex" style="
                justify-content: center;
                padding-left: 20px;
                padding-right: 20px;
              ">
              <h5>Decription</h5>
            </div>
            <div style="margin-bottom: 20px"></div>
            <div class="d-flex" style="
                justify-content: start;
                padding-left: 20px;
                padding-right: 20px;
              ">
              <p>
                {{ groupData?.description }}
              </p>
            </div>
            <div style="margin-bottom: 20px"></div>
            <div class="d-flex" style="
                justify-content: center;
                padding-left: 20px;
                padding-right: 20px;
              ">
              <h5>Supported Contribution Methods</h5>
            </div>
            <div style="margin-bottom: 20px"></div>
            <div class="d-flex" style="justify-content: center">
              <div style="height: 100px; justify-content: center; display: flex">
                <img class="w-100" src="@/assets/img/logos/mpesa.png" alt="logo" />
              </div>
              <div style="margin-left: 20px"></div>
              <div style="height: 100px; justify-content: center; display: flex">
                <img class="w-100" src="@/assets/img/logos/visa.png" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <marquee behavior="scroll" direction="left" class="bg-dark text-light py-2 font-weight-bold">
            <span v-for="(contributor, i) in groupData.external_contributors" :key="i">
              {{ contributor.name?.toUpperCase().split(" ")[0] }} -
              <span class="text-success">KES {{ contributor.amount }}</span>
              | &nbsp;
            </span>
          </marquee>
        </div>
      </div>
    </div>
  </div> -->

  <app-footer />

  <!-- Contribute Modal -->
  <div class="col-md-4">
    <div class="modal fade" id="modal-contribute" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <form @submit.prevent="contribute" class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-contribute">
              Select preferred contribution method
            </h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              ">
              <div style="width: 48%" class="radio-card">
                <input type="radio" name="payment" id="card1" v-model="checked" value="selection1" />
                <label style="
                    position: absolute;
                    margin: 20px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  " for="card1">
                  <img class="w-50" src="@/assets/img/logos/mpesa.png" alt="logo" />
                </label>
              </div>
              <div style="width: 48%" class="radio-card">
                <input type="radio" name="payment" id="card2" v-model="checked" value="selection2" />
                <label style="
                    position: absolute;
                    margin: 20px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  " for="card2">
                  <img class="w-50" src="@/assets/img/logos/visa.png" alt="logo" />
                </label>
              </div>
            </div>
            <div></div>
            <!-- <paystack :amount="amount" :email="email" :paystackkey="paystackkey" :reference="reference"
              :callback="callback" :close="close" :embed="false">
              <i class="fas fa-money-bill-alt"></i>
              Make Payment
            </paystack> -->
            <div v-if="checked === 'selection1'" class="form-group">
              <label for="recipient-name" class="col-form-label">Your Name:</label>
              <input style="margin-bottom: 0.5rem" v-model="form.name" type="text" class="form-control"
                placeholder="John Njue" id="recipient-name" />
              <label for="recipient-name" class="col-form-label">Enter M-PESA Number (For STK Push):</label>
              <input style="margin-bottom: 0.5rem" type="tel" v-model="form.phone_number" class="form-control"
                placeholder="0701254254" id="recipient-name" />
              <label for="recipient-name" class="col-form-label">Amount:</label>
              <input type="number" class="form-control" placeholder="KES" v-model="form.amount" id="recipient-name" />
              <div style="margin-bottom: 20px"></div>
              <div style="
                  padding-top: 20px;
                  padding-bottom: 10px;
                  padding-left: 20px;
                  padding-right: 20px;
                  background-color: #dddddd;
                  border-radius: 10px;
                ">
                <div class="d-flex" style="justify-content: center">
                  <p style="font-size: 16px; text-align: left">
                    M-PESA Payments powered by <strong>Sortika</strong>
                  </p>
                </div>
              </div>
              <!-- <p>
                <strong>Or manualy use these M-PESA payment instructions</strong>
                <br />
                1. Go to M-PESA Menu <br />
                2. Select Lipa na M-PESA <br />
                3. Select Paybill <br />
                4. Enter business <strong>4047737</strong> <br />
                5. Enter account name <strong>{{ groupData?.mpesa_id }}</strong>
                <br />
                6. Enter Amount <br />
                7. Enter your M-PESA Pin
              </p> -->
            </div>
            <div v-if="checked === 'selection2'" class="form-group" style="margin-bottom: 2rem">
              <!-- <StripeElement :element="cardElement" @change="event = $event" />
              <soft-button class="btn btn-outline-secondary ms-2" full-width style="
                  border: 2px solid;
                  /* padding-left: 30px;
                  padding-right: 30px; */
                  margin-right: 1rem !important;
                  margin-top: 1.5rem;
                  height: 8%;" @click="registerCard">Add</soft-button>
              <div v-if="event && event.error">{{ event.error.message }}</div> -->
              <div>
                <div ref="cardElement"></div>
                <label for="recipient-name" class="col-form-label">Your Name:</label>
                <input v-model="form.cardholderName" type="text" class="form-control" placeholder="Jane Olise"
                  style="margin-bottom: 0.5rem" />
                <label for="recipient-name" class="col-form-label">Phone Number:</label>
                <input v-model="form.phone_number" placeholder="0701254254" class="form-control"
                  style="margin-bottom: 0.5rem" />
                <label for="recipient-name" class="col-form-label">Amount:</label>
                <input type="number" class="form-control" placeholder="KES" v-model="form.amount" id="amount" />
                <div style="
                    padding: 20px;
                    background-color: #dddddd;
                    border-radius: 10px;
                    margin-top: 20px;
                  ">
                  <div class="d-flex" style="justify-content: center">
                    <p style="font-size: 14px; text-align: left">
                      You will be redirected to a
                      <strong>secure page</strong> to enter your card details
                    </p>
                    <p style="font-size: 14px; text-align: right">
                      Secured by <strong>STRIPE</strong>
                    </p>
                  </div>
                </div>

                <!-- <label>
                  <input type="checkbox" v-model="termsAccepted" class="font-weight-light" /> I agree to the
                  <a href="javascript:;" class="text-dark font-weight-bolder">Terms & Conditions</a>
                </label>
                <soft-button @click="handlePayment" class="mb-2" full-width color="success" variant="gradient">Make
                  Payment</soft-button> -->
              </div>
            </div>
            <div>
              <div v-if="selectedOption === 'option2'" class="form-group">
                <label for="recipient-name" class="col-form-label">Card Details:</label>
                <input style="margin-bottom: 0.5rem" type="text" class="form-control" placeholder="Card Holder"
                  id="recipient-name" />
                <input style="margin-bottom: 0.5rem" type="text" class="form-control"
                  placeholder="Card No. Eg. 4242 4242 4242 4242" id="recipient-name" />
                <div style="display: flex" class="form-group">
                  <input class="form-control" type="month" value="2018-11" id="example-month-input" />
                  <div style="margin-right: 1rem"></div>
                  <input class="form-control" type="number" placeholder="CVV" id="example-month-input" />
                </div>
              </div>
              <div v-if="selectedOption === 'option3'" class="form-group">
                <label for="recipient-name" class="col-form-label">Bank Details:</label>
                <p>
                  <strong>Account Name</strong> Sortika <br />
                  <strong>Account Number </strong> 123456789 <br />
                  <strong>Branch</strong> Waiyaki Way Branch <br />
                </p>
              </div>
            </div>
          </div>
          <input type="hidden" ref="goal_id" :value="groupData?.goal_id" id="" />

          <div style="padding-left: 20px; padding-right: 20px">
            <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light" ref="t_and_c" checked>
              I agree to the
              <a href="javascript:;" class="text-dark font-weight-bolder">Terms & Conditions</a>
            </soft-checkbox>
          </div>
          <div style="padding-left: 20px; padding-right: 20px">
            <small style="
                padding: 10px 20px 10px 20px;
                border-radius: 10px;
                justify-content: center;
                display: flex;
                width: 100%;
              " v-if="errorMsg != ''" class="bg-danger text-white">{{ errorMsg }}</small>
          </div>
          <div style="padding-left: 20px; padding-right: 20px">
            <small style="
                padding: 10px 20px 10px 20px;
                border-radius: 10px;
                justify-content: center;
                display: flex;
                width: 100%;
              " v-if="succesMsg != ''" class="bg-success text-dark">{{ succesMsg }}</small>
          </div>
          <div style="border-top: none" class="modal-footer">
            <!-- <soft-button class="mb-2" full-width color="success" variant="gradient" type="submit">{{
              loadingForm == true ? "Loading..." : "Contribute"
            }}</soft-button> -->
            <!-- <soft-button
              class="mb-2"
              full-width
              color="success"
              variant="gradient"
              type="submit"
              >{{
                loadingForm == true ? "Loading..." : "Contribute"
              }}</soft-button
            > -->

            <!-- <soft-button class="mb-2" full-width color="success" variant="gradient" type="submit">
              <span v-if="loadingForm" class="sr-only">Loading...</span>
              <span v-else>Contribute</span>
            </soft-button> -->

            <!-- <soft-button v-if="!loadingForm" class="my-4 mb-2" variant="gradient" color="success" type="submit"
              full-width>Contribute -->

            <soft-button v-if="!loadingForm" class="my-4 mb-2" variant="gradient" color="success" type="submit"
              full-width>Contribute
            </soft-button>

            <soft-button v-else class="my-4 mb-2" variant="gradient" color="success" disabled full-width>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </soft-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Stripe Payment Success-->

  <soft-button color="success" hidden id="successBtn" variant="gradient" class="my-4 mb-2 ms-2" style="height: 45px"
    data-bs-toggle="modal" data-bs-target="#modal-stripe-success">
    Toggle
  </soft-button>

  <div class="col-md-4">
    <div class="modal fade" id="modal-stripe-success" tabindex="-1" role="dialog" ref="modalStripeSuccess"
      aria-labelledby="modal-default" aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <client-only>
                <Vue3Lottie animationLink="https://lottie.host/4858822d-87b9-4f10-acad-01b531ec4f82/HlN4L3wxP3.json"
                  :height="200" :width="200" />
              </client-only>
            </div>
          </div>
          <div class="d-flex" style="
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 20px;
              text-align: center;
            ">
            <h4>Thank you!</h4>
          </div>
          <div class="d-flex" style="
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 20px;
              text-align: center;
            ">
            <!-- <h5>Your contribution of KES 600.00 has been received</h5> -->
            <h5>{{ paymentMessage }}</h5>
          </div>
          <div class="d-flex" style="
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 50px;
              text-align: center;
            ">
            <h6>You will recieve a confirmation SMS from us in afew</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Share Modal -->
  <div class="col-md-4">
    <div class="modal fade" id="modal-share" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none" class="modal-header">
            <h5 class="modal-title" id="modal-title-contribute">
              Share on your socials
            </h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              ">
              <ShareNetwork network="facebook" :url="base_url" :title="groupData?.name"
                :description="groupData?.description" hashtags="egroup,sortika">
                <span style="font-size: 50px; color: navy" class="fab fa-facebook"></span>
              </ShareNetwork>
              <ShareNetwork network="twitter" :url="base_url" :title="groupData?.name"
                :description="groupData?.description" hashtags="egroup,sortika">
                <span style="font-size: 50px; color: deepskyblue" class="fab fa-twitter"></span>
              </ShareNetwork>
              <ShareNetwork network="whatsapp" :url="base_url" :title="groupData?.name"
                :description="groupData?.description" hashtags="egroup,sortika">
                <span style="font-size: 50px; color: springgreen" class="fab fa-whatsapp"></span>
              </ShareNetwork>
              <ShareNetwork network="SMS" :url="base_url" :title="groupData?.name" :description="groupData?.description"
                hashtags="egroup,sortika">
                <span style="font-size: 50px; color: rgb(83, 4, 79)" class="fas fa-sms"></span>
              </ShareNetwork>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftProgress from "@/components/SoftProgress";
import Vue3Autocounter from "vue3-autocounter";
import axios from "axios";
import { Stripe } from "stripe";
import { loadStripe } from "@stripe/stripe-js";
import { Vue3Lottie } from "vue3-lottie";

// import animationData from "@/assets/img/checkmark.json";
import CheckmarkJSON from "@/assets/img/checkmark.json";

import { mapMutations, mapActions } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    AppFooter,
    SoftCheckbox,
    SoftButton,
    SoftBadge,
    SoftProgress,
    Vue3Autocounter,
    Vue3Lottie,
  },

  data() {
    return {
      selectedOption: "option1",
      checked: "selection1", //paystack public key
      email: "foobar@example.com", // Customer email
      amount: 1000000, // in kobo
      groupData: {},
      errorMsg: "",
      form: {},
      loadingForm: false,
      filter: {},
      base_url: "",
      succesMsg: "",
      stripe: null,
      cardElement: null,
      CheckmarkJSON,
      paymentMessage: "",
      showSuccessModalPop: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.setStartVar();
    // this.fetchCountries();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.$store.state.isAbsolute = false;
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    ...mapActions(["registerCustomer", "fetchCountries"]),

    // async generatePaymentIntent() {
    //   const paymentIntent = await apiCallToGeneratePaymentIntent(); // this is just a dummy, create your own API call
    //   this.elementsOptions.clientSecret = paymentIntent.client_secret;
    // },
    // pay() {
    //   this.$refs.paymentRef.submit();
    // },

    formatAmount(amount) {
      // Use toLocaleString() to format the number with a thousand separator
      return amount.toLocaleString("en-US");
    },
    setStartVar() {
      this.fetchGroup();
      this.setForm();
    },

    async triggerSuccessBtnClick() {
      if (this.$route.query.message) {
        this.paymentMessage = await this.$route.query.message;

        const button = document.getElementById("successBtn");
        if (button) {
          button.click();
        }
      } else {
        this.paymentMessage = "";
      }
    },

    fetchGroup() {
      this.base_url = window.location.href;

      let filters = {
        group_code: this.$route.query.group_code,
        inviter: this.$route.query.inviter,
        group: this.$route.query.group,
      };
      this.errorMsg = "";

      let url = process.env.VUE_APP_BASE_URL + "contribution-group?";
      for (let key in filters) {
        if (filters[key]) {
          url += `&${key}=${filters[key]}`;
        }
      }
      axios
        .get(url)
        .then((response) => {
          const data = response.data.data;
          this.groupData = data;
          this.succesMsg = "";
        })
        .catch((error) => {
          console.log(error.response?.data);
          this.errorMsg = "";
          this.succesMsg = "";
        });
    },

    percentage(saved, target) {
      return ((saved / target) * 100).toFixed(2);
    },

    setForm(value = null) {
      let group_code = this.$route.query.group_code;
      let inviter = this.$route.query.inviter;
      let group = this.$route.query.group;

      this.errorForm = null;
      this.form = value
        ? this.$clone(value)
        : {
          destination: "SP",
          phone_number: "",
          amount: "",
          goals: 0,
          mode: "Auto",
          inviter: inviter,
          group_code: group_code,
          group: group,
          type: "MPA",
        };
    },

    async stripeValidation(pk, s_id) {
      const stripePromise = await loadStripe(pk);
      const { error } = await stripePromise.confirmCardPayment(s_id, {
        payment_method: {
          card: this.$refs.cardElement,
        },
      });

      if (error) {
        console.error(error);
      } else {
        console.log("Payment Successful");
      }
    },

    async contribute() {
      let oldPhone = this.form.phone_number;
      let phone = oldPhone;
      let code = "254";

      const TnC = this.$refs.t_and_c;

      if (!TnC.checked) {
        this.errorMsg =
          "You must accept our terms and conditions to proceed on your contribution";
        return;
      }
      if (!this.form.inviter) {
        this.errorMsg = "Inviter Code Misssing";
        return;
      }
      if (phone.startsWith("0")) {
        phone = phone.substring(1);
        this.form.phone_number = code + phone;
      } else if (phone.startsWith("+")) {
        phone = phone.substring(1);
        this.form.phone_number = oldPhone;
      } else {
        this.form.phone_number = oldPhone;
      }

      this.form.goal = this.$refs.goal_id.value;

      this.loadingForm = true;
      let url = process.env.VUE_APP_BASE_URL + "contributor/deposit";

      if (this.checked == "selection2") {
        this.form.type = "STRIPE";
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then(function (response) {
            return response.json();
          })
          .then(async function (session) {
            let key = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
            let stripe = await loadStripe(key);

            return stripe.redirectToCheckout({
              sessionId: session.session.id,
            });
          })
          .then(function (result) {
            if (result.error) {
              alert(result.error.message);
            }
          })
          .catch((error) => {
            console.log("Error sending data", error);
            this.errorMsg = error.response?.message ?? "Something went wrong";
            this.loadingForm = false;
            // Handle the error here
          });
      } else if (this.checked == "selection1") {
        this.form.type = "MPA";

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then(function (response) {
            let res = response.json();
            this.succesMsg =
              res?.message ??
              "Kindly confirm your pin on the STK push on your mobile phone to process your contribution.";
            this.loadingForm = false;
            return;
          })
          .catch((error) => {
            console.log("Error sending data", error);
            this.succesMsg =
              "Kindly confirm your pin on the STK push on your mobile phone to process your contribution.";
            // this.errorMsg = error.response?.message ?? "Something went wrong";
            this.loadingForm = false;
          });
      }
    },

    callback: function (response) {
      console.log(response);
    },
    close: function () {
      console.log("Payment closed");
    },
  },
  mounted() {
    this.triggerSuccessBtnClick();

    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);

    let _stripe = Stripe(this.pk);
    const stp = _stripe;
    this.stripe = stp;
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
};
</script>

<style>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-bottom: 5px;
}

.spacer {
  width: 4px;
  height: 0;
  /* Let the spacer element take up available space */
}

.clickable-image {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
    /* Change to column layout at 600px breakpoint */
    align-items: flex-start;
    /* Align items to the left in column layout */
  }
}
</style>
