<template>
  <nav class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs" id="navbarBlur"
    data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <div class="input-group">
            <!-- <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
            <input type="text" class="form-control" :placeholder="
              this.$store.state.isRTL ? 'أكتب هنا...' : 'Type here...'
            " /> -->
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <soft-button style="padding-left: 40px; padding-right: 40px; margin-right: 5px" class="my-4 mb-2"
              variant="gradient" color="success" full-width data-bs-toggle="modal"
              data-bs-target="#modal-deposit">Deposit
            </soft-button>
          </li>
          <div style="margin-left: 10px"></div>
          <li class="nav-item d-flex align-items-center">
            <soft-button style="padding-left: 30px; padding-right: 30px; margin-right: 10px" class="my-4 mb-2"
              variant="gradient" color="success" full-width data-bs-toggle="modal"
              data-bs-target="#modal-withdraw">Withdraw
            </soft-button>
          </li>
          <div style="margin-left: 0"></div>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <div style="margin-left: 2rem"></div>
          <!-- <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" @click="toggleConfigurator" :class="textWhite ? textWhite : 'text-body'">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li> -->
          <li class="nav-item dropdown d-flex align-items-center" :class="this.$store.state.isRTL ? 'ps-2' : 'pe-2'">
            <a href="#" class="p-0 nav-link" :class="[
              textWhite ? textWhite : 'text-body',
              showMenu ? 'show' : '',
            ]" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="../../assets/img/team-2.jpg" class="avatar avatar-sm me-3" alt="user image" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Loan request</span> from
                        Njihia
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Deposit Modal -->
  <div class="col-md-4">
    <div class="modal fade" id="modal-deposit" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none;" class="modal-header">
            <h6 class="modal-title" id="modal-title-deposit">Select goal to make a deposit</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="display: flex; justify-content:space-between; align-items: center;">
              <div style="width: 48%" class="radio-card">
                <input type="radio" name="pricing" id="card1" v-model="checked" value="selection1">
                <label style="position: absolute; margin: 20px; cursor: pointer;" for="card1">
                  <h5 style="font-weight: 600; font-size: 18px; margin: -3px 0 10px 0;">General
                    Goal</h5>
                  <span style="font-weight: 500; font-size: 12px;color: #7b7b93; margin-top: -4rem">Divided between
                    goals based on their
                    allocation</span>
                </label>
              </div>
              <div style="width: 48%" class="radio-card">
                <input type="radio" name="pricing" id="card2" v-model="checked" value="selection2">
                <label style="position: absolute; margin: 20px; cursor: pointer;" for="card2">
                  <h5 style="font-weight: 600; font-size: 18px; margin: -3px 0 10px 0;">Specific
                    Goal
                  </h5>
                  <span style="font-weight: 500; font-size: 12px;color: #7b7b93; margin-top: -4rem">Deposit into a
                    specific goal you choose</span>
                </label>
              </div>
            </div>
            <div v-if="checked === 'selection1'" class="form-group">
            </div>
            <div v-if="checked === 'selection2'" class="form-group" style="margin-bottom: 2rem;">
              <select class="form-control">
                <option>Select Your Goal</option>
                <option>Be Your Own Bank</option>
                <option>Samsung Galaxy S23 Ultra</option>
                <option>Housing Fund</option>
              </select>
            </div>
            <div style="width:100%; height: 80px" class="radio-card">
              <input type="radio" id="mpesa" name="radioDisabled" v-model="selectedOption" value="option1">
              <img style="position: absolute; margin: 10px; margin-left: 20px" class="w-10 me-3 mb-0"
                src="@/assets/img/logos/mpesa.png" alt="logo" />
            </div>
            <div style="width:100%; height: 80px; margin-top: -1rem" class="radio-card">
              <input type="radio" id="visa" name="radioDisabled" v-model="selectedOption" value="option2">
              <img style="position: absolute; margin: 10px; margin-left: 20px" class="w-10 me-3 mb-0"
                src="@/assets/img/logos/visa.png" alt="logo" />
            </div>
            <div style="width:100%; height: 80px; margin-top: -1rem" class="radio-card">
              <input type="radio" id="bank" name="radioDisabled" v-model="selectedOption" value="option3">
              <img style="position: absolute; margin: 10px; margin-left: 15px" class="w-10 me-3 mb-0"
                src="@/assets/img/logos/bank.png" alt="logo" />
            </div>
            <form>
              <div v-if="selectedOption === 'option1'" class="form-group">
                <label for="recipient-name" class="col-form-label">Enter M-PESA Number (For STK Push):</label>
                <input style="margin-bottom: 1rem;" type="tel" class="form-control" placeholder="0722 111 222"
                  id="recipient-name">
                <p><strong>Or manualy use these M-PESA payment instructions</strong> <br /> 1. Go to M-PESA Menu <br />
                  2. Select Lipa na
                  M-PESA <br /> 3. Select Paybill <br /> 4. Enter business <strong>4047737</strong> <br /> 5. Enter
                  account name <strong>Fixed Goal</strong> <br /> 6. Enter Amount <br /> 7. Enter your M-PESA Pin
                  Number <br /> 8. Enter the amount
                  deposited below <br /> 9. Click Deposit</p>
              </div>
              <div v-if="selectedOption === 'option2'" class="form-group">
                <label for="recipient-name" class="col-form-label">Card Details:</label>
                <input style="margin-bottom: 0.5rem;" type="text" class="form-control" placeholder="Card Holder"
                  id="recipient-name">
                <input style="margin-bottom: 0.5rem;" type="text" class="form-control"
                  placeholder="Card No. Eg. 4242 4242 4242 4242" id="recipient-name">
                <div style="display:flex" class="form-group">
                  <input class="form-control" type="month" value="2018-11" id="example-month-input">
                  <div style="margin-right: 1rem"></div>
                  <input class="form-control" type="number" placeholder="CVV" id="example-month-input">
                </div>
              </div>
              <div v-if="selectedOption === 'option3'" class="form-group">
                <label for="recipient-name" class="col-form-label">Bank Details:</label>
                <p><strong>Account Name</strong> Sortika<br /><strong>Account Number</strong> 123456789 <br />
                  <strong>Branch</strong> Waiyaki Way Branch <br />
                </p>
              </div>
              <div class="form-group">
                <label for="recipient-name" class="col-form-label">Amount:</label>
                <input type="number" class="form-control" placeholder="KES" id="recipient-name">
              </div>
            </form>
          </div>
          <div style="border-top: none;" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark">Deposit</soft-button>
            <soft-button class="mb-2" color="warning" data-bs-dismiss="modal">Close</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Withdrawal Modal -->
  <div class="col-md-4">
    <div class="modal fade" id="modal-withdraw" tabindex="-1" role="dialog" aria-labelledby="modal-default"
      aria-hidden="true">
      <div class="modal-dialog modal- modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div style="border-bottom: none;" class="modal-header">
            <!-- <h6 class="modal-title" id="modal-title-withdraw"> Select goal to withdraw from</h6> -->
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <select-goal />
          </div>
          <div style="border-top: none;" class="modal-footer">
            <soft-button class="mb-2" variant="gradient" color="dark">Withdraw</soft-button>
            <soft-button type="button" class="mb-2" color="warning" data-bs-dismiss="modal">Close</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import SoftButton from "@/components/SoftButton.vue";
import SelectGoal from "./Withdrawal/SelectGoal.vue";
// @ts-ignore
// import VueMultiStepForm from "vue-multi-step-form";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",
  data() {
    // stepData: [];
    return {
      showMenu: false,
      selectedOption: 'option1',
      checked: 'selection1'
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: {
    Breadcrumbs,
    SoftButton,
    SelectGoal,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
<style>
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 70%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  box-shadow: 7px 7px 15px rgba(2, 28, 53, 0.03);
  cursor: pointer;
  outline: none;
  border: 1px solid #e2e6f3;
}

input[type="radio"]:before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background-color: #f9fafd;
  border: 1px solid #e2e6f3;
  border-radius: 50%;
  top: 15px;
  right: 20px;
}

input[type="radio"]:after {
  content: "";
  position: absolute;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border-radius: 50%;
  top: 19px;
  right: 24.3px;
}

input[type="radio"]:hover {
  transform: scale(1.01);
}

input[type="radio"]:checked {
  border: 3px solid #78519e;
}

input[type="radio"]:checked:after {
  background-color: #78519e;
}

.radio-card {
  height: 150px;
  width: 200px;
  position: relative;
}
</style>
