<template>
  <!-- <navbar btn-background="bg-gradient-primary" /> -->
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved2.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to Sortika eGroups!</h1>
          <p class="text-white text-lead">Let's make it count</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-bottom: 5px;
}

.spacer {
  width: 4px;
  height: 0;
  /* Let the spacer element take up available space */
}

@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
    /* Change to column layout at 600px breakpoint */
    align-items: flex-start;
    /* Align items to the left in column layout */
  }
}
</style>
