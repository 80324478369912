import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SortikaUIDashboard from "./sortika-ui-dashboard";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Vue3Autocounter from "vue3-autocounter";
import VueSweetalert2 from "vue-sweetalert2";
import VueSocialSharing from "vue-social-sharing";
import Vue3Lottie from "vue3-lottie";

const options = {
  confirmButtonColor: "#14F9AC",
  cancelButtonColor: "red",
};

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use("vue3-autocounter", Vue3Autocounter);
appInstance.use(VueSweetalert2, options);
appInstance.use(Vue3Lottie, { name: "LottieAnimation" });
appInstance.use(VueSocialSharing);
appInstance.use(SortikaUIDashboard);
appInstance.use(PerfectScrollbar, {
  watchOptions: true,
  options: {
    suppressScrollY: true,
  },
});
appInstance.mount("#app");
